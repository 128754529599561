/**
 * Difference b/w this mutation and the one in autoMutations.js
 * - Requests for just the application id and job opportunity id in the result
 */
export const updateMatch = /* GraphQL */ `
  mutation UpdateMatch(
    $input: UpdateMatchInput!
  ) {
    updateMatch(input: $input) {
      applicationId
      jobOpportunityId
      rejectionByCustomer
      reasonsForRating
      reasonForRejection
      moreInfoRequest
      moreInfoRequestMessage
      status
      subStatus
    }
  }
`;

/**
 * Difference b/w this mutation and the one in autoMutations.js
 * - Requests for just the JobOpportunity id
 */

export const updateJobOpportunity = /* GraphQL */ `
  mutation UpdateJobOpportunity(
    $input: UpdateJobOpportunityInput!
  ) {
    updateJobOpportunity(input: $input) {
      id
      jobLengthInWeeks
      jobTypeId
      location {
        cityName
        countryCode
        countryId
        countryName
        latitude
        locationId
        longitude
        stateCode
        stateId
        stateName
        wikiDataId
      }
      maxRate {
        currency
        value
      }
      minRate {
        currency
        value
      }
      organization
      overview
      regions
      requiredPositions
      requirements
      responsibilities
      skills {
        id
        infoUrl
        name
        type {
          id
          name
        }
      }
      startDate
      status
      timeCommitment
      timeOverlap
      timezone {
        label
        value
      }
      title
      visibilityLevel
    }
  }
`;

export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
      reason
    }
  }
`;

export const createJobOpportunity = /* GraphQL */ `
  mutation CreateJobOpportunity($input: CreateJobOpportunityInput!) {
    createJobOpportunity(input: $input) {
      id
      jobLengthInWeeks
      jobTypeId
      location {
        cityName
        countryCode
        countryId
        countryName
        latitude
        locationId
        longitude
        stateCode
        stateId
        stateName
        wikiDataId
      }
      maxRate {
        currency
        value
      }
      minRate {
        currency
        value
      }
      organization
      overview
      regions
      requiredPositions
      requirements
      responsibilities
      skills {
        id
        infoUrl
        name
        type {
          id
          name
        }
      }
      startDate
      status
      timeCommitment
      timeOverlap
      timezone {
        label
        value
      }
      title
      visibilityLevel
    }
  }
`;

export const createApplication = /* GraphQL */ `
  mutation CreateApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      id
      jobTypeId
    }
  }
`;


export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      agreedToMarketing
      agreedToTerms
      company
      family_name
      given_name
      careers {
        companyName
        description
        endDate
        format
        locationType
        stack
        startDate
        title
      }
      placements {
        id
        actualEndDate
        careerEndDate
        careerIsHidden
        careerStartDate
        companyName
        description
        endDate
        format
        locationType
        stack
        startDate
        title
      }
      projectsCaseStudies {
        id
        client
        description
        endDate
        workType
        link
        stack
        startDate
        title
        images
      }
       phone {
        number
        whatsAppAllowed
      }
      location {
        cityName
        countryCode
        countryId
        countryName
        latitude
        locationId
        longitude
        stateCode
        stateId
        stateName
        wikiDataId
      }
      ratePerHour {
        currency
        value
      }
      referrerCode
      socialLinks {
        type
        value
      }
      skills {
        id
        infoUrl
        name
        type {
          id
          name
        }
        experience
      }
      username
      userType
    }
  }
`;

export const updateUserAndQueryAllFields = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      address {
        city
        country
        line1
        line2
        postalCode
        state
      }
      agreedToTerms
      agreedToMarketing
      availability
      bio
      company
      careers {
        id
        companyName
        description
        endDate
        format
        locationType
        stack
        startDate
        title
      }
      placements {
        id
        actualEndDate
        careerEndDate
        careerIsHidden
        careerStartDate
        companyName
        description
        endDate
        format
        locationType
        stack
        startDate
        title
      }
      projectsCaseStudies {
        id
        client
        description
        endDate
        workType
        link
        stack
        startDate
        title
        images
      }
      companyDetails {
        address {
          city
          country
          line1
          line2
          postalCode
          state
        }
        bio
        logo
        name
        tagline
        timezone {
          label
          value
        }
        url
      }
      coverPhoto
      email
      family_name
      githubAccessToken
      given_name
      headshotKey
      identity_username
      jobRole
      knownLanguages {
        language
        level
      }
      locale
      location {
        cityName
        countryCode
        countryId
        countryName
        latitude
        locationId
        longitude
        stateCode
        stateId
        stateName
        wikiDataId
      }
      otherLinks {
        createdAt
        creator
        description
        name
        type
        updatedAt
        updater
        value
        visibility
      }
      phone {
        number
        whatsAppAllowed
      }
      profileCompletion
      profileStats
      projectStyles {
        id,
        title
      }
      ratePerHour {
        value
        currency
      }
      referralCode
      referralCount
      referrerCode
      resumeLocation
      skills {
        id
        infoUrl
        name
        type {
          id
          name
        }
        experience
      }
      socialLinks {
        type
        value
      }
      status
      tagline
      username
      userType
      visibility
    }
  }
`;

export const updatePlacement = /* GraphQL */ `
  mutation UpdatePlacement($input: UpdatePlacementInput!) {
    updatePlacement(input: $input) {
      message
    }
  }
`;

export const updateReferralCount = `
  mutation UpdateReferralCount($input: UpdateReferralCountInput) {
    updateReferralCount(input: $input) {
      message
    }
  }
`;

export const createNote = /* GraphQL */ `
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      content
      creator
      updater
      createdAt
      updatedAt
    }
  }
`;

export const createReferral = /* GraphQL */ `
  mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      message
    }
  }
`;

export const createMatch = /* GraphQL */ `
  mutation CreateMatch($input: CreateMatchInput!) {
    createMatch(input: $input) {
      applicationId
      creator
      availableStartDate
      freelancerPitch
      freelancerPitchPrefill
      jobOpportunityId
      isAccepted
      status
      subStatus
      rating
      rate {
        value
        currency
      }
      updater
      createdAt
      updatedAt
    }
  }
`;

export const gtp = /* GraphQL */ `
  mutation Gtp($input: GtpInput!) {
    gtp(input: $input) {
      success
      message
    }
  }
`;
